import { addDays } from 'date-fns'

export const formatDate = (date: string | undefined) =>
  date?.replace(/(\d{4})(\d{2})(\d{2})/g, '$2/$3/$1')

export const revertDate = (date: string) => {
  return date?.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3$1$2')
}

export const getExpirationStatus = (effectiveDate: string | undefined) => {
  if (effectiveDate) {
    const expirationDate = new Date(getAgreementExpirationDate(effectiveDate))
    const currentDate = new Date()

    if (currentDate >= expirationDate) {
      return true
    }
    return false
  }
  return false
}

export const getAgreementExpirationDate = (effectiveDate?: string) => {
  if (!effectiveDate) return 'N/A'
  const agreementStartDate = new Date(effectiveDate)

  const expirationDate = new Date(agreementStartDate)
  expirationDate.setFullYear(expirationDate.getFullYear() + 1)
  return expirationDate.toLocaleDateString()
}

export const getNewAgreementAnnualEffectiveDate = (
  activeAgreementExpirationDate?: string
) => {
  if (!activeAgreementExpirationDate) return new Date().toISOString()
  const expirationDate = new Date(activeAgreementExpirationDate)

  return addDays(expirationDate, 1).toISOString().split('T')[0]
}

export const toTitleCase = (string: string) =>
  string
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()

export const sortedObject = (unordered: any) =>
  Object.keys(unordered)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: unordered[key],
      }),
      {}
    )

export const getTableColumnsFromLength = (columns: number) => {
  switch (columns) {
    case 1:
      return 'grid-cols-1'
    case 2:
      return 'grid-cols-2'
    case 3:
      return 'grid-cols-3'
    case 4:
      return 'grid-cols-4'
    case 5:
      return 'grid-cols-5'
    case 6:
      return 'grid-cols-6'
    case 7:
      return 'grid-cols-7'
    case 8:
      return 'grid-cols-8'
  }
}

export const getBottomBorder = (length: number, i: number) =>
  i === length - 1 ? 'border-b-0' : 'border-b-2'

export const formatPhoneNumber = (string: string) => {
  const onlyNumber = string.replace(/\D/g, '')
  return onlyNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0')
