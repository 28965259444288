import axios, { AxiosRequestConfig } from 'axios'

import { AbsoluteMinimumsDataProps } from '@/interfaces/absolute-minimums'
import { AccessorialsProps } from '@/interfaces/accessorials'
import { ExceptionsProps } from '@/interfaces/exceptions'
import { FuelTableProps } from '@/interfaces/fuel'
import { TariffForAgreementBodyProps } from '@/interfaces/index'
import { AgreementsStatusEnum } from '@/interfaces/signed-agreement'
import { formatDate } from '@/utils/format-helpers'

interface ErrorResponse {
  errorMessage: string
  statusCode: number
}

export const apiStatusFetch = async () => {
  const ERROR_MSG = `Error [CAT]: Error in DebugPanel on helper "apiStatusFetch"`

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: '',
        method: 'get',
      }),
    })

    const apiData = await response.json()

    // Instrumentation: Datadog
    console.info(`Log [CAT]: Fetching Internal API status from DebugPanel`)

    return apiData
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const customersSearch = async (searchQuery: string) => {
  const ERROR_MSG = `Error [CAT]: Error on page "/customers/search${searchQuery}" on helper "customersSearch"`

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `customers/search/${searchQuery}`,
        method: 'get',
      }),
    })

    const { customers } = await response.json()

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Searching Customers from page "/" using query: "${searchQuery}"`
    )

    return customers
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const customerFetch = async (id: number, page: string) => {
  const ERROR_MSG = `Error [CAT]: Error on page ${page} on helper "customerFetch"`
  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `customers/${id}`,
        method: 'get',
      }),
    })

    const data = await response.json()

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Fetching Customer record from database "${id}"  page ${page}`
    )

    return {
      name: data.name,
      accountId: data.accountNumber,
      tariffId: data.tariffId,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const customerCreate = async (id?: string) => {
  const ERROR_MSG = `Error [CAT]: Error on page "/customers/${id}" on helper "customerCreate"`

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `customers/create`,
        method: 'put',
        bodyData: { accountNumber: id },
      }),
    })

    const { customer } = await response.json()

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Creating Customer record in database "${id}" from page "/customers/${id}"`
    )

    return {
      name: customer.name,
      accountId: customer.accountNumber,
      tariffId: customer.tariffId,
      agreements: customer.agreements,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const agreementFetch = async (agreementId: number, page: string) => {
  const ERROR_MSG = `Error [CAT]: Error on page ${page} on helper "agreementFetch"`
  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/${agreementId}`,
        method: 'get',
      }),
    })

    const data = await response.json()
    const agreement = data?.agreement
    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Fetching Agreement record from database "${agreementId}"  page ${page}`
    )

    return {
      id: agreement.id,
      accountNumber: agreement.accountNumber,
      createdAt: agreement.createdAt,
      agreementTariffId: agreement?.agreementTariffId,
      agreementStartDate:
        agreement.agreementStartDate && agreement.agreementStartDate,
      agreementStatus: agreement.status,
      customer: {
        name: agreement.customer.name,
        tariffId: agreement.customer.tariffId,
        agreements: agreement.customer.agreements,
        phone: agreement.customer.phone,
        address: agreement.customer.address,
      },
      tariff: {
        ratingTariff: agreement.tariff && agreement.tariff.ratingTariff,
        effectiveDate: agreement.tariff && agreement.tariff.effectiveDate,
      },
      fuelTable: {
        id: agreement.fuelTable?.id,
        name: agreement.fuelTable?.name,
        baseCents: agreement.fuelTable?.baseCents,
        basePercent: agreement.fuelTable?.baseCents,
        fscIndex: agreement.fuelTable?.fscIndex,
        increaseCents: agreement.fuelTable?.increaseCents,
        increasePercent: agreement.fuelTable?.increasePercent,
        recordCode: agreement.fuelTable?.recordCode,
        subIndex: agreement.fuelTable?.subIndex,
      },
      accessorials: agreement.accessorials && agreement.accessorials,
      absoluteMinimums:
        agreement.absoluteMinimums && agreement.absoluteMinimums,
      exceptions: agreement.exceptions,
      stateExceptions: agreement?.stateExceptions,
      terminalExceptions: agreement?.terminalExceptions,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const agreementCreate = async (id?: string) => {
  const ERROR_MSG = `Error [CAT]: Error on page "/customers/${id}" on helper "agreementCreate"`

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/create`,
        method: 'post',
        bodyData: { accountNumber: id },
      }),
    })

    const { agreement } = await response.json()

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Creating Agreement "${agreement.id}" from page "/customers/${id}"`
    )

    return {
      id: agreement.id,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const agreementDelete = async (accountId: number, id: number) => {
  const ERROR_MSG = `Error [CAT]: Error on page "/customers/${id}" on helper "agreementDelete"`

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/delete/${accountId}`,
        method: 'delete',
      }),
    })

    const { agreement } = await response.json()

    console.info(
      `Log [CAT]: Deleting Agreement "${agreement.id}" from page "/customers/${id}"`
    )

    return {
      id: agreement.id,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const tariffFetch = async (id: number) => {
  const ERROR_MSG = `Error [CAT]: Error on page "agreements/${id}/general-rates" on helper "tariffFetch"`

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/tariffs`,
        method: 'get',
      }),
    })

    const { tariffs } = await response.json()

    const tariffOptions = tariffs.map(
      (tariff: { effectiveDate: string }, i: number) => {
        return {
          displayText: formatDate(tariff.effectiveDate),
          value: `${i}`,
        }
      }
    )

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Updating Tariffs from page "agreements/${id}/general-rates`
    )

    return {
      tariffs,
      tariffOptions,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const tariffCreate = async (
  id: number,
  body: TariffForAgreementBodyProps
) => {
  const ERROR_MSG = `Error [CAT]: Error on page "agreements/${id}/general-rates" on helper "tariffCreate"`

  try {
    const response = await fetch('/api/internal-api-proxy', {
      method: 'post',
      body: JSON.stringify({
        endpoint: 'agreements/tariffs/create',
        method: 'put',
        bodyData: body,
      }),
    })

    const data = await response.json()
    console.info(
      `Log [CAT]: Saving Tariff ${data.tariff.id} to Agreement ${id} from page "agreements/${id}/general-rates"`
    )

    return {
      data,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const exceptionsCreate = async (id: number, body: ExceptionsProps) => {
  const ERROR_MSG = `Error [CAT]: Error on page "agreements/${id}/exceptions" on helper "exceptionsCreate"`

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/exceptions/create`,
        method: 'put',
        bodyData: body,
      }),
    })

    const data = await response.json()
    console.info(
      `Log [CAT]: Saving Exceptions to Agreement ${id} from page "agreements/${id}/exceptions"`
    )

    return {
      data,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    console.log(e)

    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const exceptionsFetch = async (agreementId: number) => {
  const ERROR_MSG = `Error [CAT]: Error on page  "agreements/${agreementId}/exceptions" on helper "exceptionsFetch"`
  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/exceptions/${agreementId}`,
        method: 'get',
      }),
    })

    const { exceptions } = await response.json()

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Fetching Exceptions records from database "${agreementId}" on page  "agreements/${agreementId}/exceptions"`
    )

    return {
      exceptions,
      // globalExceptions: exceptions.globalExceptions,
      // classExceptions: exceptions.ClassExceptionsProps,
      // stateExceptions: exceptions.stateExceptions,
      // terminalExceptions: exceptions.terminalExceptions,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const accessorialsCreate = async (
  id: string | string[] | undefined,
  accessorials: AccessorialsProps
) => {
  const ERROR_MSG = `Error [CAT]: Error on page "agreements/${id}/accessorials" on helper "accessorialsCreate"`

  const body = {
    agreementId: Number(id),
    accessorials: accessorials,
  }

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/accessorials/create`,
        method: 'post',
        bodyData: body,
      }),
    })

    const { accessorials } = await response.json()
    console.info(
      `Log [CAT]: Saving Accessorial to Agreement ${id} from page "agreements/${id}/accessorials"`
    )

    return {
      accessorials,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    console.log(e)

    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const accessorialsFetch = async (agreementId: number) => {
  const ERROR_MSG = `Error [CAT]: Error on page  "agreements/${agreementId}/accessorials" on helper "accessorialsFetch"`
  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/accessorials/${agreementId}`,
        method: 'get',
      }),
    })

    const { accessorials } = await response.json()

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Fetching Accessorial records from database "${agreementId}" on page  "agreements/${agreementId}/accessorials"`
    )

    return {
      accessorials,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const accessorialDelete = async (slug: string, agreementId: number) => {
  const ERROR_MSG = `Error [CAT]: Error on page  "agreements/${agreementId}/accessorials" on helper "accessorialDelete"`
  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/accessorials/delete/${slug}`,
        method: 'delete',
      }),
    })

    const { accessorial } = await response.json()

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Deleting Accessorial records from database "${slug}" on page  "agreements/${agreementId}/accessorials"`
    )

    return {
      accessorial,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const absoluteMinimumsCreate = async (
  id: number,
  absoluteMinimums: AbsoluteMinimumsDataProps
) => {
  const ERROR_MSG = `Error [CAT]: Error on page "agreements/${id}/absolute-minimums" on helper "absoluteMinimumsCreate"`

  const body = {
    agreementId: Number(id),
    absoluteMinimums: absoluteMinimums,
  }

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/absolute-minimums/create`,
        method: 'post',
        bodyData: body,
      }),
    })

    const { absoluteMinimums } = await response.json()

    console.info(
      `Log [CAT]: Saving Accessorial to Agreement ${id} from page "agreements/${id}/absolute-minimums"`
    )

    return {
      absoluteMinimums,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    console.log(e)
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const absoluteMinimumsFetch = async (id: number) => {
  const ERROR_MSG = `Error [CAT]: Error on page "agreements/${id}/absolute-minimums" on helper "absoluteMinimumsFetch"`

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/absolute-minimums/${id}`,
        method: 'get',
      }),
    })

    const { absoluteMinimums } = await response.json()
    console.info(
      `Log [CAT]: Saving Accessorial to Agreement ${id} from page "agreements/${id}/absolute-minimums"`
    )

    return {
      absoluteMinimums,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    console.log(e)

    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const fuelTableCreate = async (
  id: string | string[] | undefined,
  fuel: FuelTableProps
) => {
  const ERROR_MSG = `Error [CAT]: Error on page "agreements/${id}/fuel" on helper "fuelTableCreate"`

  const body = {
    agreementId: Number(id),
    ...fuel,
  }

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/fuel-tables/create`,
        method: 'put',
        bodyData: body,
      }),
    })

    const { fuelTable } = await response.json()
    console.info(
      `Log [CAT]: Saving Fuel Table to Agreement ${id} from page "agreements/${id}/fuel"`
    )

    return {
      fuelTable,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    console.log(e)

    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const fuelTableFetch = async (agreementId: number) => {
  const ERROR_MSG = `Error [CAT]: Error on page  "agreements/${agreementId}/fuel" on helper "fuelTableFetch"`
  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/fuel-tables/${agreementId}`,
        method: 'get',
      }),
    })

    const data = await response.json()

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Fetching Fuel Table records from database "${agreementId}" on page  "agreements/${agreementId}/fuel"`
    )

    return {
      id: data.fuelTable && data.fuelTable.id,
      name: data.fuelTable && data.fuelTable.name,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    console.log(e)
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const signAgreementUploadS3 = async (
  file: any,
  agreementId: number,
  accountNumber: string | undefined,
  agreementStatus: AgreementsStatusEnum
) => {
  const ERROR_MSG = `Error [CAT]: Error on page  "agreements/${agreementId}/summary" on helper "signAgreementUploadToS3"`

  const body = {
    agreementId: agreementId,
    accountNumber: accountNumber,
    agreementStatus: agreementStatus,
  }

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        endpoint: `agreements/signed-agreements/create`,
        method: 'post',
        bodyData: body,
      }),
    })

    const result = await response.json()

    const uploadConfig: AxiosRequestConfig<File> = {
      headers: {
        'content-type': 'application/pdf',
        'Access-Control-Allow-Origin': '*',
      },
    }

    const data = await axios.put(result.presignedUrl, file, uploadConfig)

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Uploading Pdf to AWS S3 Bucket "agreement-${agreementId}.pdf" from page  "agreements/${agreementId}/summary"`
    )

    return {
      status: data.status,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    console.log(e)
    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}

export const signAgreementsUpdate = async (
  agreementId: number,
  agreementStatus: AgreementsStatusEnum
) => {
  const ERROR_MSG = `Error [CAT]: Error on page  "agreements/${agreementId}/signed-agreement" on helper "signAgreementsUpdate"`

  const body = {
    agreementId: agreementId,
    agreementStatus: agreementStatus,
  }

  try {
    const response = await fetch(`/api/internal-api-proxy`, {
      method: 'post',
      body: JSON.stringify({
        method: 'put',
        endpoint: 'agreements/signed-agreements/update',
        bodyData: body,
      }),
    })

    const data = await response.json()
    console.log('status', response)

    // Instrumentation: Datadog
    console.info(
      `Log [CAT]: Updating agreement status on agreement "${agreementId}" from page  "agreements/${agreementId}/signed-agreement"`
    )

    return {
      agreementStatus: data.agreementStatus,
    }
  } catch (e) {
    const typedError = e as { statusCode: number; errorMessage: string }
    console.log(e)

    // Instrumentation: Datadog
    console.error(ERROR_MSG, typedError?.errorMessage)

    // Instrumentation: Sentry
    throw new Error(`${ERROR_MSG} -- ${typedError?.errorMessage}`)
  }
}
